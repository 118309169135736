import { Box, Grid, MenuItem, Modal, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// import { useFormik } from 'formik';
import ArySelect from 'framework/base/components/ArySelect';
import { fontSizes } from 'framework/base/constants/arySystemSettings';
// import { systemSettingsValidationSchema } from 'pages/validations/aryFormValidations';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setDoBootstrap } from 'redux/slices/systemSlice';
import { useStore } from 'react-redux';
import { useAryMutation } from '../hooks/useAryGraphql';
import { EDIT_FONT_SIZE } from '../constants/aryGraphqlSchemas';
import AryLoadingButton from './AryLoadingButton';

function PreventZoomModal({ x, y, open, onClose, data }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const reduxStore = useStore();

  const modalStyle = {
    position: 'absolute',
    top: y,
    left: x,
    width: '20rem',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '1.75rem',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    zIndex: 100000000
  };

  const [editFontSize, { loading: editFontSizeLoading }] =
    useAryMutation(EDIT_FONT_SIZE);

  const [fontSize, setFontSize] = useState(null);

  useEffect(() => {
    if (data?.fontSize) {
      setFontSize(data?.fontSize);
    }
  }, [data?.fontSize]);

  if (!open) return null;

  return (
    <Modal open={open} onClose={onClose}>
      <Box style={modalStyle} className="custom-modal">
        <Grid container rowSpacing={theme.padding.gridSmallRowSpacing}>
          <Grid item xs={12}>
            <Typography>
              برای تغییر سایز فونت از باکس زیر اقدام نمایید
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ArySelect
              label="اندازه فونت"
              value={fontSize}
              onChange={(event) => setFontSize(event.target.value)}
            >
              <MenuItem value={fontSizes.large}>بزرگ</MenuItem>
              <MenuItem value={fontSizes.medium}>متوسط</MenuItem>
              <MenuItem value={fontSizes.small}>کوچک</MenuItem>
            </ArySelect>
          </Grid>
          <Grid item xs={12}>
            <AryLoadingButton
              loading={editFontSizeLoading}
              size="small"
              variant="contained"
              onClick={async () => {
                await editFontSize(fontSize);
                reduxStore.dispatch(setDoBootstrap(true));
                window.location.reload();
                onClose();
              }}
            >
              {t('Register')}
            </AryLoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default PreventZoomModal;
